export const LESSON_TIME_CANDIDATES: any = {
  offline: {
    basic: [90, 120],
    advanst: [90, 120],
    international: [90],
  },
  online: {
    basic: [45, 60],
    advanst: [45, 60],
    international: [45, 60],
  },
};

export const MONTHLY_FEES: any = {
  offline: {
    basic: {
      90: {
        1: 36300,
        2: 72600,
        3: 108900,
        4: 145200,
        5: 181500,
      },
      120: {
        1: 48400,
        2: 96800,
        3: 145200,
        4: 193600,
        5: 242000,
      },
    },
    advanst: {
      90: {
        1: 42900,
        2: 85800,
        3: 128700,
        4: 171600,
        5: 214500,
      },
      120: {
        1: 57200,
        2: 114400,
        3: 171600,
        4: 228800,
        5: 286000,
      },
    },
  },
  online: {
    basic: {
      45: {
        // 1: 16800,
        2: 32400,
        3: 48600,
        4: 64800,
        5: 81000,
      },
      60: {
        1: 22400,
        2: 43200,
        3: 64800,
        4: 86400,
        5: 108000,
      },
    },
    advanst: {
      45: {
        // 1: 19800,
        2: 38400,
        3: 57600,
        4: 76800,
        5: 96000,
      },
      60: {
        1: 26400,
        2: 51200,
        3: 76800,
        4: 102400,
        5: 128000,
      },
    },
  },
};

export const ADMISSION_FEES: any = {
  offline: 30000,
  online: 15000,
};
